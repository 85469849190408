import React from "react";
import { useNavigate } from 'react-router-dom';

const routeNavigate = (Component) => {
    return (props) => {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
};


export default routeNavigate;
