import logo from '../logo.png';
import '../css/App.css';
import configFile from '../appConfig.json';
import React from 'react';
import {InputTextarea} from 'primereact/inputtextarea';
import {InputText} from 'primereact/inputtext';
import {Fieldset} from 'primereact/fieldset';
import {Button} from 'primereact/button';
import {formatCode, multiTurnAIAnswer} from "../js/common";
import {Splitter, SplitterPanel} from 'primereact/splitter';
import {Toast} from 'primereact/toast';
import {v4 as uuidv4} from 'uuid';
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import Cookies from 'js-cookie';
import 'primeicons/primeicons.css';
import {Sidebar} from 'primereact/sidebar';
import {Slider} from "primereact/slider";
import {Menu} from 'primereact/menu';
import {Tooltip} from 'primereact/tooltip';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';
import {Tag} from 'primereact/tag';
import routeNavigate from "../js/routeNavigate";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Image } from 'primereact/image';


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.contentPanel = React.createRef();
        this.toast = React.createRef();
        this.menu = React.createRef();
        this.op = React.createRef();

        this.ai = "Gemini";

        this.understandTopic = [{
            title: "工作的建议",
            content: "目前正处于职业瓶颈期，正在考虑如何突破该瓶颈，请给一些建议"
        }, {
            title: "寻找上海的特色美食", content: "目前正在上海旅游，想尝试一些非常有上海特色的美食，请推荐不下10家餐厅。"
        }, {
            title: "提高沟通技能", content: "如何在人与人沟通时，尽可能的让对方理解自己的想法，同时也能更好的理解别人想表达的。"
        }];

        this.creationTopic = [{
            title: "创意食谱",
            content: "模仿川菜风格，创新一道菜，要求有猪肉，麻辣味，还要有绿叶菜,并且为该菜进行命名"
        }, {
            title: "搞笑电影剧本大纲", content: "模仿周星驰的搞笑风格，创造出一部搞笑电影剧本大纲，要求1000字左右，详细描述人物内心世界和感情戏"
        }, {
            title: "邮件自动回复内容", content: "创作一段邮件自动回复内容，要求严肃得体的公文，并礼貌告诉让发件人在节日后回主动联系本人。"
        }];

        this.exploreTopic = [{
            title: "海岛旅游推荐",
            content: "推荐五个世界上值得旅游的海岛。给出选择原因及详细旅游攻略"
        }, {
            title: "量子科普视频推荐",
            content: "推荐三部关于量子力学相关的科普视频，并且给予简单介绍和观看链接"
        }, {title: "华语流行歌曲推荐", content: "推荐五首现在正流行华语歌曲"}];


        this.menuitems = [{
            label: 'GeminiAI安全设置', icon: 'pi pi-fw pi-shield', command: () => {
                this.setState({
                    safetyDialogVisible: true
                })
            }
        }, {
            label: '常规设置', icon: 'pi pi-fw pi-cog', command: () => {
                this.setState({
                    sidebarVisible: true
                })
            }
        }]

        this.safetyCategoryList = [{
            name: '屏蔽不安全内容的几率较低、中等或较高(屏蔽大部分)', code: 'BLOCK_LOW_AND_ABOVE'
        }, {
            name: '屏蔽出现中等或高概率的不安全内容(屏蔽部分)', code: 'BLOCK_MEDIUM_AND_ABOVE'
        }, {
            name: '屏蔽存在高风险的不安全内容(屏蔽少部分)', code: 'BLOCK_ONLY_HIGH'
        }, {
            name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'
        }

        ];

        this.state = {
            chatList: [],
            userContent: '',
            chatLocalStorageList: [],
            chatLocalStorageUUID: '',
            sidebarVisible: false,
            temperature: 50,
            safetyDialogVisible: false,
            rolePlaying: '',
            appkey: '',
            apiUrl: '',
            topK: 40,
            topP: 75,
            isNew: true,
            username: configFile.default_user_name,
            HARM_CATEGORY_HARASSMENT: {name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'},
            HARM_CATEGORY_HATE_SPEECH: {name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'},
            HARM_CATEGORY_SEXUALLY_EXPLICIT: {name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'},
            HARM_CATEGORY_DANGEROUS_CONTENT: {name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'},
            base64:'',
            allowChat: true,
        }

        //get all chat history from Local Storage
        Object.keys(localStorage).forEach(key => {
            const value = JSON.parse(localStorage.getItem(key));
            this.state.chatLocalStorageList.push({uuid: key, title: value.title})
        });
        this.getSettingsFromCookie()
        this.getSafetySettingsFromCookie();
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.contentPanel.current) {
            this.contentPanel.current.scrollTop = this.contentPanel.current.scrollHeight - this.contentPanel.current.clientHeight;
        }

        // this.displayData();

        // 这里可以进行其他的条件检查，根据需要执行其他操作
    }

    getSafetySettingsFromCookie = () => {
        const safetySettingsValue = Cookies.get(configFile.safety_settings);
        if (safetySettingsValue != null) {
            const safetySettings = JSON.parse(safetySettingsValue);

            safetySettings.forEach(safetySetting => {
                switch (safetySetting.category) {
                    case 'HARM_CATEGORY_HARASSMENT':
                        this.state.HARM_CATEGORY_HARASSMENT = safetySetting.threshold;
                        break;
                    case 'HARM_CATEGORY_HATE_SPEECH':
                        this.state.HARM_CATEGORY_HATE_SPEECH = safetySetting.threshold;
                        break;
                    case 'HARM_CATEGORY_SEXUALLY_EXPLICIT':
                        this.state.HARM_CATEGORY_SEXUALLY_EXPLICIT = safetySetting.threshold;
                        break;
                    case 'HARM_CATEGORY_DANGEROUS_CONTENT':
                        this.state.HARM_CATEGORY_DANGEROUS_CONTENT = safetySetting.threshold;
                        break;
                    default:
                }


            });


        }
    }
    getSettingsFromCookie = () => {
        const temperatureValue = Cookies.get(configFile.temperature);
        const rolePlayingValue = Cookies.get(configFile.role_playing);
        const appkeyValue = Cookies.get(configFile.app_key);
        const apiUrlValue = Cookies.get(configFile.api_url);
        const usernameValue = Cookies.get(configFile.user_name);
        const topKValue = Cookies.get(configFile.topK);
        const topPValue = Cookies.get(configFile.topP);
        this.state.temperature = temperatureValue != null ? parseInt(temperatureValue) : 50;
        this.state.rolePlaying = rolePlayingValue != null ? rolePlayingValue : '';
        this.state.appkey = appkeyValue != null ? appkeyValue : '';
        this.state.apiUrl = apiUrlValue != null ? apiUrlValue : '';
        this.state.username = usernameValue != null ? usernameValue : configFile.default_user_name;
        this.state.topK = topKValue != null ? parseInt(topKValue) : 40;
        this.state.topP = topPValue != null ? parseInt(topPValue) : 75;

        // this.setState({
        //     temperature: temperatureValue != null ? parseInt(temperatureValue) : 50,
        //     rolePlaying: rolePlayingValue != null ? rolePlayingValue : '',
        //     appkey: appkeyValue != null ? appkeyValue : '',
        //     apiUrl: apiUrlValue != null ? apiUrlValue : '',
        //     username: usernameValue != null ? usernameValue : configFile.default_user_name,
        //     topK: topKValue != null ? parseInt(topKValue) : 40,
        //     topP: topPValue != null ? parseInt(topPValue) : 75,
        // })

    }
    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (this.state.userContent.trim() === "") {

                setTimeout(() => {
                    this.setState({
                        userContent: ""
                    })
                }, 10);


                this.toast.current.show({severity: 'info', summary: '请输入', detail: '请输入对话......'});
                this.forceUpdate();
                return;
            }
            this.postUserContent();
        }
    };

    legendTemplate = (chat) => {
        if (chat.role === configFile.model_role) {
            return (<div className="flex align-items-center text-primary">
                <span className="pi pi-prime mr-3" style={{color: "#436EA2"}}></span>
                <span className="font-bold text-lg" style={{color: "#436EA2"}}>{chat.user}</span>
            </div>);
        } else {

            return (<div className="flex align-items-center text-primary">
                <span className="pi pi-user mr-3" style={{color: "green"}}></span>
                <span className="font-bold text-lg" style={{color: "green"}}>{chat.user}</span>
            </div>);
        }

    }

    // (
    //     // if (chat.role === configFile.model_role
    //     <div className="flex align-items-center text-primary" style={{color: "red"}}>
    //     <span className="pi pi-user mr-3" style={{color: "red"}}></span>
    //     <span className="font-bold text-lg" style={{color: "red"}}>{user}</span>
    // </div>
    // );

    postUserContent = async (): void => {
        let question = this.state.userContent.trim()
        let imageBase64 = this.state.base64;
        if (question === "") {
            this.toast.current.show({severity: 'info', summary: '请输入', detail: '请输入对话......'});
            setTimeout(() => {
                this.setState({
                    userContent: ""
                })
            }, 10);
            return;
        }

        setTimeout(() => {
            this.setState({
                userContent: "",
                isNew: false,
                base64: '',
                allowChat: imageBase64 === ''
            })
        }, 10);
        if (imageBase64 !== '') {
            this.state.chatList.push({
                user: this.state.username,
                role: configFile.user_role,
                statement: question,
                type: configFile.chat_type_statement,
                image: imageBase64,
            });
        } else {
            this.state.chatList.push({
                user: this.state.username,
                role: configFile.user_role,
                statement: question,
                type: configFile.chat_type_statement,
            });
        }

        this.state.chatList.push({
            user: this.ai,
            role: configFile.model_role,
            statement: "等待AI回答，请稍后......",
            type: configFile.chat_type_other
        });

        await this.callAI(imageBase64 === '');
    }

    getSettings = (): [] => {
        return [{
            category: 'HARM_CATEGORY_HARASSMENT',
            threshold: this.state.HARM_CATEGORY_HARASSMENT.code
        }, {category: 'HARM_CATEGORY_HATE_SPEECH', threshold: this.state.HARM_CATEGORY_HATE_SPEECH.code}, {
            category: 'HARM_CATEGORY_SEXUALLY_EXPLICIT', threshold: this.state.HARM_CATEGORY_SEXUALLY_EXPLICIT.code
        }, {
            category: 'HARM_CATEGORY_DANGEROUS_CONTENT', threshold: this.state.HARM_CATEGORY_DANGEROUS_CONTENT.code
        }

        ]
    }


    callAI = async (isAllowStorage) => {
        let tempCandidates = -1;

        let tempData = "";
        let errorMessage = "";
        await multiTurnAIAnswer(this.state.appkey, this.state.apiUrl, this.state.chatList, this.state.temperature,
            this.state.topK, this.state.topP, this.state.rolePlaying, this.getSettings()).then(function (data) {
            let result;
            if (data.candidates) {
                tempCandidates = data.candidates.length;
                result = data.candidates[0].content.parts[0].text;
            } else if (data.promptFeedback) {
                result = '系统无法回答。原因是: ' + data.promptFeedback.blockReason;
            }
            tempData = result
        }).catch(function (error) {
            if (error.response) {
                errorMessage = error.response.data.error.message

            } else {
                errorMessage = error.toString()
            }

        });

        if (tempCandidates  >1 ) {
            this.toast.current.show({
                severity: 'info',
                summary: '有多个结果',
                detail: '有多个结果，请注意!!!!',
                life: 10000
            });
        }
        if (errorMessage !== "") {
            this.state.chatList.pop();
            this.toast.current.show({severity: 'error', summary: '错误', detail: errorMessage, life: 5000});
            return;
        }


        const index = this.state.chatList.length - 1
        this.displayData(tempData, ("content-" + index).toString())


        this.state.chatList.pop();
        this.state.chatList.push({
            user: this.ai, role: configFile.model_role, statement: tempData, type: configFile.chat_type_statement
        })
        if (isAllowStorage) {
            this.updateLocalStorage();
        }

        this.forceUpdate();
    }


    displayData = (data, id) => {
        let index = 0;
        const ss = document.getElementById(id);
        let tempData = '';
        this.displayDataInterval = setInterval(() => {
            if (index < data.length) {
                tempData = tempData + data[index]
                ss.innerHTML = formatCode(tempData);
                index++;
                this.forceUpdate();
            } else {
                clearInterval(this.displayDataInterval); // 清除定时器
            }
        }, 20);
    };

    updateLocalStorage = () => {
        let item = localStorage.getItem(this.state.chatLocalStorageUUID)
        if (item == null) {
            const newUUID = uuidv4();
            const title = this.state.chatList[0].statement.trim().substring(0, 20)
            const value = {title: title, content: this.state.chatList}
            window.localStorage.setItem(newUUID, JSON.stringify(value))
            this.setState({
                chatLocalStorageUUID: newUUID
            })

            // this.state.chatLocalStorageUUID = newUUID;
            this.state.chatLocalStorageList.push({uuid: newUUID, title: title})

        } else {
            let itemObject = JSON.parse(item)
            itemObject.content = this.state.chatList
            window.localStorage.setItem(this.state.chatLocalStorageUUID, JSON.stringify(itemObject))
        }

    }

    confirmDeleteHistoryItem = (uuid) => {
        const accept = () => {
            localStorage.removeItem(uuid);
            if (this.state.chatLocalStorageUUID === uuid) {
                this.setState({
                    chatList: [], chatLocalStorageUUID: "", isNew: true
                })
            }

            this.setState({
                chatLocalStorageList: this.state.chatLocalStorageList.filter(item => {
                    return item.uuid !== uuid
                })
            })
        }

        confirmDialog({
            message: '一旦删除无法恢复，你确认删除该历史记录吗？',
            header: '删除警告',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept
        });
    };


    uploadPicture = (event) => {

        const maxFileSize = 1024 * 1024 * 3; // 3MB (以字节为单位，可以根据需要调整大小)

        const file = event.target.files[0];
        if ( file.size > maxFileSize) {
            this.toast.current.show({ severity: 'error', summary: '大小限制', detail:  "图片大小不超过3M",  life: 5000});
            event.target.value = '';
            return;
        }

        if (file) {
            this.convertToBase64(file);
        }
    };

    convertToBase64 = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64String = reader.result;

            // const img = new Image();
            // img.src = event.target.result;
            // img.onload = () => {
            //
            //     let  aa =  { width: img.width,  height: img.height}
            //     this.setState({ dimensions: aa});
            //
            // }

            // 在这里可以使用 base64String，比如将其传递给其他函数或者组件
            // const fileExtension = file.name.split('.').pop();

            this.setState({ base64: base64String});
            // console.log('Base64:', base64String);
        };
        reader.readAsDataURL(file);
    };

    render() {
        const {
            userContent,
            chatList,
            chatLocalStorageList,
            sidebarVisible,
            temperature,
            safetyDialogVisible,
            rolePlaying,
            topK,
            topP,
            appkey,
            apiUrl,
            username,
            HARM_CATEGORY_HARASSMENT,
            HARM_CATEGORY_HATE_SPEECH,
            HARM_CATEGORY_SEXUALLY_EXPLICIT,
            HARM_CATEGORY_DANGEROUS_CONTENT,
            isNew,
            base64,
            allowChat,
        } = this.state;
        const {navigate} = this.props; // 通过 props 获取导航函数

        return (<div className="App">


            <Splitter style={{height: '100%'}}>
                <SplitterPanel className="flex justify-content-center" size={15} minSize={10}>
                    <div className="leftContainer">

                        <div className="historyContainer-newChat" onClick={(e) => {
                            const parent = e.currentTarget.parentNode
                            const children = Array.from(parent.children[1].children)
                            children.forEach((child) => {
                                // 移除特定类名，比如 'yourClassNameToRemove'
                                child.classList.remove('historyContainer-sub-active');
                            });

                            this.setState({
                                chatList: [], chatLocalStorageUUID: "", isNew: true,
                                allowChat: true
                            })
                        }}>
                            <div className="historyItem">
                                <div className="historyItem-left-div"><strong>新对话</strong></div>
                                <div className="right-div"><span className="pi pi-plus-circle"></span>
                                </div>
                            </div>
                        </div>


                        <div className="historyContainer">
                            {

                                chatLocalStorageList.map((item, index) => {
                                    return <div className="historyContainer-sub" key={index}>
                                        <div className="historyItem">
                                            <div className='historyItem-left-div' onClick={(e) => {
                                                let itemObject = JSON.parse(localStorage.getItem(item.uuid))

                                                const parent = e.target.parentNode.parentNode.parentNode;
                                                const children = Array.from(parent.children);
                                                children.forEach((child) => {
                                                    // 移除特定类名，比如 'yourClassNameToRemove'
                                                    child.classList.remove('historyContainer-sub-active');
                                                });
                                                e.target.parentNode.parentNode.classList.add('historyContainer-sub-active');

                                                this.setState({
                                                    chatList: itemObject.content,
                                                    chatLocalStorageUUID: item.uuid,
                                                    isNew: false,
                                                })
                                            }}> {item.title}</div>
                                            <div className="right-div"><span className="pi pi-times-circle"
                                                                             onClick={this.confirmDeleteHistoryItem.bind(this, item.uuid)}></span>
                                            </div>
                                        </div>

                                    </div>
                                })}
                        </div>

                        <div className="footSetting">
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: '15% 85%',
                                alignItems: 'center',
                                cursor: 'pointer',
                                userSelect: 'none',
                                marginBottom: "10px",
                                borderTop: '1px solid #ccc',
                                paddingTop: '10px',
                                width: "100%"

                            }}
                                 onClick={(e) => {

                                     navigate('/Grammar');
                                 }}

                            >
                                <div style={{textAlign: 'right', marginRight: '5px'}}>
                                    <span className="pi pi-check-square"></span>
                                </div>
                                <div><strong> 语法检查</strong></div>

                            </div>


                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: '15% 85%',
                                alignItems: 'center',
                                cursor: 'pointer',
                                userSelect: 'none',
                                marginBottom: "10px",
                                borderTop: '1px solid #ccc',
                                paddingTop: '10px',
                                width: "100%"

                            }}
                                 onClick={(e) => {

                                     navigate('/Translation');
                                 }}

                            >
                                <div style={{textAlign: 'right', marginRight: '5px'}}>
                                    <span className="pi pi-globe"></span>
                                </div>
                                <div><strong> 翻译</strong></div>

                            </div>

                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: '15% 85%',
                                alignItems: 'center',
                                cursor: 'pointer',
                                userSelect: 'none',
                                paddingTop: '10px',
                                borderTop: '1px solid #ccc'

                            }}
                                 onClick={(event) => this.menu.current.toggle(event)} aria-controls="popup_menu"
                                 aria-haspopup
                            >
                                <div style={{textAlign: 'right', marginRight: '5px'}}>
                                    <span className="pi pi-th-large"></span>
                                </div>
                                <div><strong>MIMI CAT 设置</strong></div>

                            </div>
                            <Menu model={this.menuitems} popup ref={this.menu} style={{marginBottom: "10px"}}
                                  id="popup_menu"/>

                        </div>

                    </div>

                </SplitterPanel>
                <SplitterPanel className="flex align-items-center justify-content-center" size={85}
                               style={{position: 'relative'}}>
                    <div className="contentTopPanel">
                        <div className="brandDiv">

                            <img src={logo} alt="logo" className="logo" style={{width: "50px", borderRadius: "50%"}}/>
                            <span
                                style={{fontSize: "20px", marginLeft: "10px", marginRight: "5px", fontWeight: "bold"}}>MIMI CAT</span>
                            <Tag value="2.0"></Tag>

                        </div>

                    </div>
                    <div id="contentPanel" className="contentPanel" ref={this.contentPanel}>

                        {

                            isNew ? <div className="card welcomeCard">
                                <div className="welcome-first-div">
                                        <span style={{
                                            fontSize: "30px", fontWeight: "bold"
                                        }}>MIMI 欢迎你！！！</span><br/><br/>
                                    <span
                                        style={{fontSize: "16px"}}>告诉我你的想法或从以下选择一个话题：</span><br/><br/>

                                </div>
                                <div className="card welcome-content-div">
                                    <div className="welcome-content-child-div">

                                        <span className="welcome-content-child-div-title">理解</span>

                                        {this.understandTopic.map((item, index) => {
                                            return <span key={index} className="topicChip" onClick={() => {
                                                this.setState({
                                                    userContent: item.content
                                                })
                                                setTimeout(() => {
                                                    this.postUserContent();

                                                }, 500)
                                            }}>{item.title}</span>


                                        })

                                        }
                                    </div>
                                    <div className="welcome-content-child-div">
                                        <span className="welcome-content-child-div-title">创意</span>

                                        {this.creationTopic.map((item, index) => {
                                            return <span key={index} className="topicChip" onClick={() => {
                                                this.setState({
                                                    userContent: item.content
                                                })
                                                setTimeout(() => {
                                                    this.postUserContent();

                                                }, 500)
                                            }}>{item.title}</span>


                                        })

                                        }

                                    </div>
                                    <div className="welcome-content-child-div">
                                        <span className="welcome-content-child-div-title">探索</span>
                                        {this.exploreTopic.map((item, index) => {
                                            return <span key={index} className="topicChip" onClick={() => {
                                                this.setState({
                                                    userContent: item.content
                                                })
                                                setTimeout(() => {
                                                    this.postUserContent();

                                                }, 500)
                                            }}>{item.title}</span>


                                        })

                                        }


                                    </div>

                                </div>

                            </div> : chatList.map((item, index) => {
                                return <div className="card" key={index}><Fieldset
                                    legend={this.legendTemplate(item)}>
                                    {/*<span className="m-1" dangerouslySetInnerHTML={{__html: item.statement }}/>*/}
                                    <span className="m-1"
                                          dangerouslySetInnerHTML={{__html: formatCode(item.statement)}}
                                          id={"content-" + index}/>

                                    <div style={{marginTop: "10px"}}>
                                        { item.image ?
                                            <Image src = {item.image} alt = "Image" width = "200" preview /> : ''
                                        }
                                    </div>
                                    <div className="statementIconDiv">
                                        <Button icon="pi pi-volume-up" rounded text
                                                                              aria-label="Filter" tooltip="播放"
                                                                              tooltipOptions={{
                                                                                  position: 'top', mouseTrackTop: 15
                                                                              }}
                                                                              onClick={(e) => {
                                                                                  // const parent = e.currentTarget.parentNode
                                                                                  // const children = Array.from(parent.children[1].children)

                                                                                  if ('speechSynthesis' in window) {
                                                                                      if (window.speechSynthesis.speaking) {
                                                                                          window.speechSynthesis.cancel();
                                                                                          // const  iconSpan = e.currentTarget.children[0].classList
                                                                                          // iconSpan.remove('pi-stop-circle')
                                                                                          // iconSpan.add('pi-volume-up')

                                                                                      } else {
                                                                                          const spanElement = document.getElementById("content-" + index);
                                                                                          const text = spanElement.textContent;
                                                                                          if (text !== "") {

                                                                                              const msg = new SpeechSynthesisUtterance();
                                                                                              msg.text = text;
                                                                                              // msg.lang = this.languageItem[rightLanguageActiveIndex].language;
                                                                                              msg.rate = 1;
                                                                                              msg.pitch = 1;
                                                                                              msg.volume = 1;
                                                                                              // 使用浏览器的语音合成进行朗读
                                                                                              window.speechSynthesis.speak(msg);

                                                                                              // const  iconSpan = e.currentTarget.children[0].classList
                                                                                              // iconSpan.remove('pi-volume-up')
                                                                                              // iconSpan.add('pi-stop-circle')

                                                                                          }
                                                                                      }


                                                                                  } else {
                                                                                      this.toast.current.show({
                                                                                          severity: 'error',
                                                                                          summary: '错误',
                                                                                          detail: '抱歉，您的浏览器不支持语音合成功能。',
                                                                                          life: 5000
                                                                                      });
                                                                                  }

                                                                              }}
                                        />
                                    </div>
                                </Fieldset> <br/></div>
                            })}
                    </div>

                    <div className="card flex flex-column md:flex-row gap-3  footer">

                        {
                            base64 !== "" ? (
                                    <div style={{width: "100px"}}>
                                    <OverlayPanel ref={this.op}>
                                        <img  src={base64} alt="ImageBase64"   style={{ width: '100px', height: 'auto' }} ></img>
                                    </OverlayPanel>
                                <Button size="small" className="fileUpload" label="已加载" severity="success" aria-label="Search"  onMouseOver={(e) => {
                                    this.op.current.toggle(e)
                                }}><input type="file"  accept="image/png, image/jpeg, image/webp, image/heic, image/heif" onChange={(e) => {
                                    this.uploadPicture(e);

                                } } /></Button>
                                    </div>
                            ) :(
                                <div>
                                    <Button icon=" pi pi-image" className="fileUpload" severity="success" aria-label="Search" >
                                        <input type="file" name="" id=""  accept="image/png, image/jpeg, image/webp, image/heic, image/heif" onChange={(e) => {
                                        this.uploadPicture(e);
                                    } } /></Button>
                                </div>
                            )

                        }


                        <div className="p-inputgroup flex-2">
                            {/*<Button icon="pi pi-undo" className="p-button-danger" label=" 清除" onClick={() => this.setState({*/}
                            {/*    userContent: ""*/}
                            {/*})}/>*/}


                            <span className="p-inputgroup-addon pi pi-undo"
                                  style={{backgroundColor: "red", cursor: "pointer", color: "white"}}
                                  onClick={() => this.setState({
                                      userContent: ""
                                  })}>
                            </span>

                            {allowChat ? (
                                <InputTextarea style={{overflow: "hidden"}} placeholder="请输入内容......" rows={1}
                                               autoResize
                                               cols={50} id="questionTextarea" value={userContent}

                                               onChange={(e) => this.setState({
                                                   userContent: e.target.value
                                               })}
                                               onKeyDown={this.handleKeyDown}

                                />


                            ) : (
                                <InputTextarea style={{overflow: "hidden"}} placeholder="系统暂不支持带图片的多轮聊天" rows={1}
                                               autoResize
                                               cols={50} id="questionTextarea" value={userContent}
                                               disabled  />
                            ) }

                            <Tooltip target=".messageTextarea"
                                     content={"回车键(Enter)发送消息"}
                                     position="top" event="both"/>

                            <span className="p-inputgroup-addon pi pi-send messageTextarea"
                                  style={{backgroundColor: "#436EA2", cursor: "pointer", color: "white"}}
                                  onClick={this.postUserContent}>
                            </span>
                            {/*<Button className="pi pi-send" label=" 发送" onClick={this.postUserContent}/>*/}


                        </div>


                    </div>

                </SplitterPanel>
            </Splitter>
            <Toast ref={this.toast}/>
            <ConfirmDialog/>

            <Sidebar visible={sidebarVisible} position="right" className="w-full md:w-30rem lg:w-30rem"
                     onHide={() => this.setState({
                         sidebarVisible: false
                     })}>
                <div className="sidebarContainer">
                    <Fieldset legend="网络设置">
                        <div className="sidebarSubItem">
                            <div className="configItem">
                                <div className={"configItem-left-div"}>
                                    App Key:
                                </div>
                                <div className={"configItem-right-div"}>
                                    <InputText placeholder="App Key" className="w-full" value={appkey}
                                               onChange={(e) => this.setState({
                                                   appkey: e.target.value.trim()
                                               })}/>
                                </div>
                            </div>
                            <div className="configItem">
                                <div className={"configItem-left-div"}>
                                    API网址:
                                </div>
                                <div className={"configItem-right-div"}>
                                    <InputText placeholder="URL" className="w-full" value={apiUrl}
                                               onChange={(e) => this.setState({
                                                   apiUrl: e.target.value.trim()
                                               })}/>

                                </div>
                            </div>
                        </div>
                    </Fieldset>
                    <p></p>
                    <Fieldset legend="个性化设置">
                        <div className="sidebarSubItem">
                            <div className="configItem">
                                <div className={"configItem-left-div"}>
                                    你的昵称:
                                </div>
                                <div className={"configItem-right-div"}>
                                    <InputText placeholder="输入你的昵称，系统默认会显示为：You" className="w-full"
                                               value={username} onChange={(e) => this.setState({
                                        username: e.target.value
                                    })}/>
                                </div>
                            </div>
                            <div className="configItem">
                                <div className={"configItem-left-div"}>
                                    温度:
                                </div>
                                <div className={"configItem-right-div"}
                                     style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

                                    <Tooltip target=".sliderTemperature>.p-slider-handle"
                                             content={"较低的温度适合需要更具确定性或不够开放的回答，\n较高的温度可以产生更加多样化或更具创意的结果。\n温度为 0 表示确定性的结果"}
                                             position="top" event="both"/>

                                    <Slider className="w-full sliderTemperature" style={{marginRight: "10px"}}
                                            value={temperature}
                                            onChange={(e) => this.setState({
                                                temperature: e.value
                                            })} max={100} min={1} step={1}/> <InputText keyfilter="int" disabled
                                                                                        value={temperature + "%"}
                                                                                        className="w-3"
                                                                                        onChange={(e) => this.setState({
                                                                                            temperature: e.target.value.trim()
                                                                                        })}/>
                                </div>
                            </div>
                            <div className="configItem">
                                <div className={"configItem-left-div"}>
                                    Top-K
                                </div>
                                <div className={"configItem-right-div"}
                                     style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

                                    <Tooltip target=".sliderTopK>.p-slider-handle"
                                             content={"Top-k采样时考虑的是概率最高的词元集(单词数量)上限，\n这种方法允许其他高分tokens(单词)有机会被选中。" + "\n例如选择前三个tokens意味着将top-k 参数设置为 3。"}
                                             position="top" event="both"/>

                                    <Slider className="w-full sliderTopK" style={{marginRight: "10px"}} value={topK}
                                            onChange={(e) => this.setState({
                                                topK: e.value
                                            })} max={100} min={1} step={1}/> <InputText keyfilter="int" disabled
                                                                                        value={topK + "个"}
                                                                                        className="w-4"
                                                                                        onChange={(e) => this.setState({
                                                                                            topK: e.target.value.trim()
                                                                                        })}/>
                                </div>
                            </div>
                            <div className="configItem">
                                <div className={"configItem-left-div"}>
                                    Top-P
                                </div>
                                <div className={"configItem-right-div"}
                                     style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

                                    <Tooltip target=".sliderTopP>.p-slider-handle"
                                             content={"Top-P 在采样时要考虑的tokens(单词)的最大累加概率，\n" + "Top-p 通常设置为较高的值,目的是限制可能被采样的低概率 token 的长尾。\n如果 Top-K 和 Top-P 都启用，则 p 在 k 之后起作用。"}
                                             position="top" event="both"/>

                                    <Slider className="w-full sliderTopP" style={{marginRight: "10px"}} value={topP}
                                            onChange={(e) => this.setState({
                                                topP: e.value
                                            })} max={100} min={1} step={1}/> <InputText keyfilter="int" disabled
                                                                                        value={topP + "%"}
                                                                                        className="w-3"
                                                                                        onChange={(e) => this.setState({
                                                                                            topP: e.target.value.trim()
                                                                                        })}/>
                                </div>
                            </div>
                            <div className="configItem">
                                <div className={"configItem-left-div"}>
                                    角色扮演:
                                </div>
                                <div className={"configItem-right-div"}>
                                    <InputTextarea style={{overflow: "hidden"}} rows={2} cols={26} id="questionTextarea"
                                                   value={rolePlaying} autoResize
                                                   onChange={(e) => this.setState({
                                                       rolePlaying: e.target.value
                                                   })}/>
                                </div>
                            </div>

                        </div>
                    </Fieldset>

                    <div className="settingPanelFoot">
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: '50% 50%',
                            alignItems: 'center',
                            cursor: 'pointer',
                            userSelect: 'none',
                            textAlign: "center"
                        }}>
                            <div>
                                <Button className="pi pi-save" label=" 保存" severity="success" style={{width: "120px"}}
                                        onClick={() => {
                                            Cookies.set(configFile.temperature, temperature, {expires: configFile.cookie_expire_date})
                                            Cookies.set(configFile.app_key, appkey, {expires: configFile.cookie_expire_date})
                                            Cookies.set(configFile.api_url, apiUrl, {expires: configFile.cookie_expire_date})
                                            Cookies.set(configFile.user_name, username, {expires: configFile.cookie_expire_date})
                                            Cookies.set(configFile.topK, topK, {expires: configFile.cookie_expire_date})
                                            Cookies.set(configFile.topP, topP, {expires: configFile.cookie_expire_date})
                                            Cookies.set(configFile.role_playing, rolePlaying, {expires: configFile.cookie_expire_date})

                                            this.setState({
                                                sidebarVisible: false
                                            })
                                        }}/>

                            </div>
                            <div><Button label=" 取消" className="pi pi-times" severity="danger"
                                         style={{width: "120px"}} onClick={() => {
                                this.getSettingsFromCookie()
                                this.setState({
                                    sidebarVisible: false
                                })
                            }}/>
                            </div>

                        </div>
                    </div>
                </div>
            </Sidebar>

            <Dialog header="Gemini AI 安全设置" visible={safetyDialogVisible} style={{width: '50vw'}}
                    onHide={() => this.setState({
                        safetyDialogVisible: false
                    })}>

                <div className="securityParent">
                    <div className="securityParent-child">
                        <div className="securityParent-inner-child-left" id="HARM_CATEGORY_HARASSMENT">骚扰内容</div>
                        <div className="securityParent-inner-child-right"><Dropdown value={HARM_CATEGORY_HARASSMENT}
                                                                                    onChange={(e) => this.setState({
                                                                                        HARM_CATEGORY_HARASSMENT: e.value
                                                                                    })}
                                                                                    options={this.safetyCategoryList}
                                                                                    optionLabel="name"
                                                                                    placeholder="请选择"
                                                                                    className="w-full md:w-24rem"/>
                        </div>
                    </div>
                    <div className="securityParent-child">
                        <div className="securityParent-inner-child-left" id="HARM_CATEGORY_HATE_SPEECH">仇恨言论和内容
                        </div>
                        <div className="securityParent-inner-child-right"><Dropdown value={HARM_CATEGORY_HATE_SPEECH}
                                                                                    onChange={(e) => this.setState({
                                                                                        HARM_CATEGORY_HATE_SPEECH: e.value
                                                                                    })}
                                                                                    options={this.safetyCategoryList}
                                                                                    optionLabel="name"
                                                                                    placeholder="请选择"
                                                                                    className="w-full md:w-24rem"/>
                        </div>
                    </div>
                    <div className="securityParent-child">
                        <div className="securityParent-inner-child-left"
                             id="HARM_CATEGORY_SEXUALLY_EXPLICIT">露骨色情内容
                        </div>
                        <div className="securityParent-inner-child-right"><Dropdown
                            value={HARM_CATEGORY_SEXUALLY_EXPLICIT} onChange={(e) => this.setState({
                            HARM_CATEGORY_SEXUALLY_EXPLICIT: e.value
                        })} options={this.safetyCategoryList} optionLabel="name" placeholder="请选择"
                            className="w-full md:w-24rem"/></div>
                    </div>
                    <div className="securityParent-child">
                        <div className="securityParent-inner-child-left" id="HARM_CATEGORY_DANGEROUS_CONTENT">危险内容
                        </div>
                        <div className="securityParent-inner-child-right"><Dropdown
                            value={HARM_CATEGORY_DANGEROUS_CONTENT} onChange={(e) => this.setState({
                            HARM_CATEGORY_DANGEROUS_CONTENT: e.value
                        })} options={this.safetyCategoryList} optionLabel="name" placeholder="请选择"
                            className="w-full md:w-24rem"/></div>
                    </div>
                    <div className="securityParent-child" style={{marginTop: '50px'}}>
                        <div className="securityParent-inner-child-left"></div>
                        <div className="securityParent-inner-child-right" style={{marginLeft: '50px'}}>
                            <Button
                                style={{width: '120px', marginRight: '50px'}} className="pi pi-save" label=" 保存"
                                severity="success" onClick={() => {
                                const safetySettings = [{
                                    category: 'HARM_CATEGORY_HARASSMENT',
                                    threshold: HARM_CATEGORY_HARASSMENT
                                }, {category: 'HARM_CATEGORY_HATE_SPEECH', threshold: HARM_CATEGORY_HATE_SPEECH}, {
                                    category: 'HARM_CATEGORY_SEXUALLY_EXPLICIT',
                                    threshold: HARM_CATEGORY_SEXUALLY_EXPLICIT
                                }, {
                                    category: 'HARM_CATEGORY_DANGEROUS_CONTENT',
                                    threshold: HARM_CATEGORY_DANGEROUS_CONTENT
                                },

                                ]
                                Cookies.set(configFile.safety_settings, JSON.stringify(safetySettings), {expires: configFile.cookie_expire_date})
                                this.setState({
                                    safetyDialogVisible: false
                                })
                            }}/>

                            <Button
                                style={{width: '120px'}} label=" 取消" severity="danger" className="pi pi-times"
                                onClick={() => {
                                    this.getSafetySettingsFromCookie();
                                    this.setState({
                                        safetyDialogVisible: false
                                    })
                                }}/></div>
                    </div>
                </div>
            </Dialog>
        </div>);
    }
}

export default routeNavigate(Home);
