import React from "react";
import "../css/Translation.css";
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import 'primeicons/primeicons.css';
import Cookies from "js-cookie";
import configFile from "../appConfig.json";
import {Toast} from 'primereact/toast';
import {formatCode, singleAIAnswer} from "../js/common";
import routeNavigate from "../js/routeNavigate";


class Grammar extends React.Component {
    constructor(props) {
        super(props);

        this.toast = React.createRef();

        this.state = {
            appkey: '',
            apiUrl: '',
            topK: 40,
            topP: 75,
            temperature: 40,
            toBeCheckingContent: '',
            checkingResult:'',
            HARM_CATEGORY_HARASSMENT: {name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'},
            HARM_CATEGORY_HATE_SPEECH: {name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'},
            HARM_CATEGORY_SEXUALLY_EXPLICIT: {name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'},
            HARM_CATEGORY_DANGEROUS_CONTENT: {name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'},
        }

        this.getSafetySettingsFromCookie();
        this.getSettingsFromCookie();
    }

    getSettingsFromCookie = () => {
        const temperatureValue = Cookies.get(configFile.temperature);
        const appkeyValue = Cookies.get(configFile.app_key);
        const apiUrlValue = Cookies.get(configFile.api_url);
        const topKValue = Cookies.get(configFile.topK);
        const topPValue = Cookies.get(configFile.topP);
        this.state.temperature = temperatureValue != null ? parseInt(temperatureValue) : 50;
        this.state.appkey = appkeyValue != null ? appkeyValue : '';
        this.state.apiUrl = apiUrlValue != null ? apiUrlValue : '';
        this.state.topK = topKValue != null ? parseInt(topKValue) : 40;
        this.state.topP = topPValue != null ? parseInt(topPValue) : 75;
    }

    getSafetySettingsFromCookie = () => {
        const safetySettingsValue = Cookies.get(configFile.safety_settings);
        if (safetySettingsValue != null) {
            const safetySettings = JSON.parse(safetySettingsValue);

            safetySettings.forEach(safetySetting => {
                switch (safetySetting.category) {
                    case 'HARM_CATEGORY_HARASSMENT':
                        this.state.HARM_CATEGORY_HARASSMENT = safetySetting.threshold;
                        break;
                    case 'HARM_CATEGORY_HATE_SPEECH':
                        this.state.HARM_CATEGORY_HATE_SPEECH = safetySetting.threshold;
                        break;
                    case 'HARM_CATEGORY_SEXUALLY_EXPLICIT':
                        this.state.HARM_CATEGORY_SEXUALLY_EXPLICIT = safetySetting.threshold;
                        break;
                    case 'HARM_CATEGORY_DANGEROUS_CONTENT':
                        this.state.HARM_CATEGORY_DANGEROUS_CONTENT = safetySetting.threshold;
                        break;
                    default:
                }


            });


        }
    }

    postContentToGrammarChecking = async () => {

        let content = this.state.toBeCheckingContent.trim();
        if (content === "") {
            this.toast.current.show({severity: 'info', summary: '输入', detail: '请输入待分析的外语内容'});
            setTimeout(() => {
                this.setState({
                    toBeCheckingContent: ""
                })
            }, 10);
            return;
        }

        const grammarCheckingContent = '检查如下内容的语法是否正确？如果不正确，请给出正确语法的内容及解释说明。"' + content+'"。';

        this.setState({
            checkingResult: "语法检查中，请稍后......"
        })
        await  this.callAIForGrammarChecking(grammarCheckingContent)
    }


    callAIForGrammarChecking  =  async (content) => {

        let tempData = "";
        let errorMessage = "";

        await singleAIAnswer(this.state.appkey, this.state.apiUrl, content, this.state.temperature, this.state.topK, this.state.topP,  this.getSettings())
            .then(function (data) {
                let result;
                if (data.candidates) {
                    result = data.candidates[0].content.parts[0].text;
                } else if (data.promptFeedback) {
                    result = '系统无法回答。原因是: ' + data.promptFeedback.blockReason;
                }
                tempData = result
            }).catch(function (error) {
            if (error.response) {
                errorMessage = error.response.data.error.message

            } else {
                errorMessage = error.toString()
            }

        });

        if (errorMessage !== "") {
            this.toast.current.show({severity: 'error', summary: '错误', detail: errorMessage, life: 5000});
            return;
        }
        this.setState({
            checkingResult: tempData
        })


    }

getSettings = (): [] => {
    return [
        {category: 'HARM_CATEGORY_HARASSMENT', threshold: this.state.HARM_CATEGORY_HARASSMENT.code},
        {category: 'HARM_CATEGORY_HATE_SPEECH', threshold: this.state.HARM_CATEGORY_HATE_SPEECH.code},
        {
            category: 'HARM_CATEGORY_SEXUALLY_EXPLICIT',
            threshold: this.state.HARM_CATEGORY_SEXUALLY_EXPLICIT.code
        },
        {
            category: 'HARM_CATEGORY_DANGEROUS_CONTENT',
            threshold: this.state.HARM_CATEGORY_DANGEROUS_CONTENT.code
        }

    ]
}
    render() {

        const {
            toBeCheckingContent,
            checkingResult
        } = this.state;
        const { navigate } = this.props; // 通过 props 获取导航函数

        return (
            <div className="Translation">



                <div className="tranCard mainContainer">
                    <span><h1 style={{textAlign: "center"}} >MIMI语法检查</h1></span>
                    <div className="content">
                        <div className="left"><InputTextarea autoResize value={toBeCheckingContent} rows={10}
                                                             style={{width: "100%"}} onChange={(e) => this.setState({
                            toBeCheckingContent: e.target.value
                        })}/>
                        </div>
                        <div className="right">
                            <div className="tranCard translationContentBox"><div className=" translationContentSubBox" id="translationContent"
                            dangerouslySetInnerHTML={{__html: formatCode(checkingResult)}}></div>
                            </div>

                        </div>
                    </div>
                    <div className="foot">
                        <Button label="语法检查" className="functionBt" rounded onClick={this.postContentToGrammarChecking.bind(this)}/>
                        <Button label="清空" severity="warning" className="functionBt" rounded onClick={ () =>  {
                            this.setState({
                                toBeCheckingContent: "",
                                checkingResult:""
                            })
                        } }/>
                        <Button label="返回主页" severity="help" className="functionBt" rounded onClick={ () =>  {
                            navigate('/');
                        } }/>
                    </div>

                </div>
                <Toast ref={this.toast}/>

            </div>
        );
    }

}


export default routeNavigate(Grammar);
