import logo from '../logo.png';
import React from "react";
import "../css/Translation.css";
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {TabMenu} from 'primereact/tabmenu';
import 'primeicons/primeicons.css';
import Cookies from "js-cookie";
import configFile from "../appConfig.json";
import {Toast} from 'primereact/toast';
import {singleAIAnswer} from "../js/common";
import routeNavigate from "../js/routeNavigate";


class Translation extends React.Component {


    constructor(props) {
        super(props);

        this.toast = React.createRef();

        this.languageItem = [
            {label: '中文', code: "中文", language: "zh-CN"},
            {label: 'English', code: "英文",  language: "en-US"},
            {label: 'Japanese', code: "日文" ,  language: "ja-JP"},
            {label: 'French(France)', code: "法语(法国)" ,  language: "fr-FR"},
            {label: 'Russian', code: "俄语" ,  language: "ru-RU"}


        ];

        this.state = {
            appkey: '',
            apiUrl: '',
            topK: 40,
            topP: 75,
            temperature: 50,
            toBeTranslatedContent: '',
            translationResult:'',
            leftLanguageActiveIndex:0,
            rightLanguageActiveIndex:1,
            HARM_CATEGORY_HARASSMENT: {name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'},
            HARM_CATEGORY_HATE_SPEECH: {name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'},
            HARM_CATEGORY_SEXUALLY_EXPLICIT: {name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'},
            HARM_CATEGORY_DANGEROUS_CONTENT: {name: '允许所有内容(全部不屏蔽)', code: 'BLOCK_NONE'},
        }

        this.getSafetySettingsFromCookie();
        this.getSettingsFromCookie();
    }

    getSettingsFromCookie = () => {
        const temperatureValue = Cookies.get(configFile.temperature);
        const appkeyValue = Cookies.get(configFile.app_key);
        const apiUrlValue = Cookies.get(configFile.api_url);
        const topKValue = Cookies.get(configFile.topK);
        const topPValue = Cookies.get(configFile.topP);
        this.state.temperature = temperatureValue != null ? parseInt(temperatureValue) : 50;
        this.state.appkey = appkeyValue != null ? appkeyValue : '';
        this.state.apiUrl = apiUrlValue != null ? apiUrlValue : '';
        this.state.topK = topKValue != null ? parseInt(topKValue) : 40;
        this.state.topP = topPValue != null ? parseInt(topPValue) : 75;
    }

    getSafetySettingsFromCookie = () => {
        const safetySettingsValue = Cookies.get(configFile.safety_settings);
        if (safetySettingsValue != null) {
            const safetySettings = JSON.parse(safetySettingsValue);

            safetySettings.forEach(safetySetting => {
                switch (safetySetting.category) {
                    case 'HARM_CATEGORY_HARASSMENT':
                        this.state.HARM_CATEGORY_HARASSMENT = safetySetting.threshold;
                        break;
                    case 'HARM_CATEGORY_HATE_SPEECH':
                        this.state.HARM_CATEGORY_HATE_SPEECH = safetySetting.threshold;
                        break;
                    case 'HARM_CATEGORY_SEXUALLY_EXPLICIT':
                        this.state.HARM_CATEGORY_SEXUALLY_EXPLICIT = safetySetting.threshold;
                        break;
                    case 'HARM_CATEGORY_DANGEROUS_CONTENT':
                        this.state.HARM_CATEGORY_DANGEROUS_CONTENT = safetySetting.threshold;
                        break;
                    default:
                }


            });


        }
    }

    postContentToTranslation = async () => {

        let content = this.state.toBeTranslatedContent.trim();
        if (content === "") {
            this.toast.current.show({severity: 'info', summary: '输入', detail: '请输入待翻译内容'});
            setTimeout(() => {
                this.setState({
                    toBeTranslatedContent: ""
                })
            }, 10);
            return;
        }

        const translationContent = '将如下内容由'+this.languageItem[ this.state.leftLanguageActiveIndex].code
            + '翻译为' + this.languageItem[ this.state.rightLanguageActiveIndex].code+'。"' + content+'"。';

        this.setState({
            translationResult: "翻译中，请稍后......"
        })
        await  this.callAIForTranslation(translationContent)
    }


    callAIForTranslation  =  async (content) =>{

        let tempData = "";
        let errorMessage = "";

        await singleAIAnswer(this.state.appkey, this.state.apiUrl, content, this.state.temperature, this.state.topK, this.state.topP,  this.getSettings())
            .then(function (data) {
                let result;
                if (data.candidates) {
                    result = data.candidates[0].content.parts[0].text;
                } else if (data.promptFeedback) {
                    result = '系统无法回答。原因是: ' + data.promptFeedback.blockReason;
                }
                tempData = result
            }).catch(function (error) {
            if (error.response) {
                errorMessage = error.response.data.error.message

            } else {
                errorMessage = error.toString()
            }

        });

        if (errorMessage !== "") {
            this.toast.current.show({severity: 'error', summary: '错误', detail: errorMessage, life: 5000});
            return;
        }
        this.setState({
            translationResult: tempData
        })


    }

getSettings = (): [] => {
    return [
        {category: 'HARM_CATEGORY_HARASSMENT', threshold: this.state.HARM_CATEGORY_HARASSMENT.code},
        {category: 'HARM_CATEGORY_HATE_SPEECH', threshold: this.state.HARM_CATEGORY_HATE_SPEECH.code},
        {
            category: 'HARM_CATEGORY_SEXUALLY_EXPLICIT',
            threshold: this.state.HARM_CATEGORY_SEXUALLY_EXPLICIT.code
        },
        {
            category: 'HARM_CATEGORY_DANGEROUS_CONTENT',
            threshold: this.state.HARM_CATEGORY_DANGEROUS_CONTENT.code
        }

    ]
}
    render() {

        const {
            toBeTranslatedContent,
            leftLanguageActiveIndex,
            rightLanguageActiveIndex,
            translationResult
        } = this.state;
        const { navigate } = this.props; // 通过 props 获取导航函数

        return (
            <div className="Translation">



                <div className="tranCard mainContainer">
                    <span><h1 style={{textAlign: "center"}} >MIMI翻译</h1></span>
                    <div className="header">
                        <div className="left">
                            <TabMenu model={this.languageItem} activeIndex={leftLanguageActiveIndex}  onTabChange={(e) => {
                                this.setState({

                                    leftLanguageActiveIndex:  e.index
                                })

                            }}/>
                        </div>
                        <div className="topCenter">

                            <Button icon="pi pi-arrow-right-arrow-left" rounded text onClick={() => {
                                const  tempLeft = rightLanguageActiveIndex;
                                const  tempRight = leftLanguageActiveIndex;

                                this.setState({
                                    leftLanguageActiveIndex: tempLeft,
                                    rightLanguageActiveIndex: tempRight
                                })
                                this.forceUpdate();

                            }
                            }/>

                        </div>
                        <div className="right"><TabMenu model={this.languageItem} activeIndex={rightLanguageActiveIndex} onTabChange={(e) => {
                            this.setState({

                                rightLanguageActiveIndex:  e.index
                            })

                        }}/>
                        </div>
                    </div>
                    <div className="content">
                        <div className="left"><InputTextarea autoResize value={toBeTranslatedContent} rows={10}
                                                             style={{width: "100%"}} onChange={(e) => this.setState({
                            toBeTranslatedContent: e.target.value
                        })}/>
                        </div>
                        <div className="right">
                            <div className="tranCard translationContentBox"><div className=" translationContentSubBox" id="translationContent">{translationResult}</div>
                                <div><Button icon="pi pi-volume-up" rounded text aria-label="Filter" onClick={(e) => {
                                    if ('speechSynthesis' in window) {
                                        if (window.speechSynthesis.speaking) {
                                            window.speechSynthesis.cancel();
                                            // const  iconSpan = e.currentTarget.children[0].classList
                                            // iconSpan.remove('pi-stop-circle')
                                            // iconSpan.add('pi-volume-up')
                                            return;
                                        }

                                        const div = document.getElementById('translationContent');
                                        const text = div.textContent;
                                        if(text !== "") {

                                            const msg = new SpeechSynthesisUtterance();
                                            msg.text = text;
                                            msg.lang = this.languageItem[rightLanguageActiveIndex].language;
                                            msg.rate = 1;
                                            msg.pitch = 1;
                                            msg.volume = 1;
                                            // 使用浏览器的语音合成进行朗读
                                            window.speechSynthesis.speak(msg);
                                            // const  iconSpan = e.currentTarget.children[0].classList
                                            // iconSpan.remove('pi-volume-up')
                                            // iconSpan.add('pi-stop-circle')
                                        }

                                    } else {
                                        this.toast.current.show({severity: 'error', summary: '错误', detail: '抱歉，您的浏览器不支持语音合成功能。', life: 5000});
                                    }


                                }} /></div></div>

                        </div>
                    </div>
                    <div className="foot">
                        <Button label="翻译" className="functionBt" rounded onClick={this.postContentToTranslation.bind(this)}/>
                        <Button label="清空" severity="warning" className="functionBt" rounded/>
                        <Button label="返回主页" severity="help" className="functionBt" rounded onClick={ () =>  {
                            navigate('/');
                        } }/>
                    </div>

                </div>
                <Toast ref={this.toast}/>

            </div>
        );
    }

}


export default routeNavigate(Translation);
