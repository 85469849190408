import axios from 'axios';
import configFile from '../appConfig.json'

// const defaultUrl = configFile.gemini_service_url;
// const defulctAppkey= configFile.gemini_app_key;

export function multiTurnAIAnswer(appkey, apiUrl, chats, temperature, topK, topP, rolePlaying, safetySettings) {
    if (appkey ==='' || apiUrl === '') {
       // return  {promptFeedback: {blockReason: "请先设置App Key和Api Url"}}

        return new Promise((resolve, reject) => {
            // 模拟一个异步操作
            setTimeout(() => {
                resolve( {promptFeedback: {blockReason: "请先设置App Key和Api Url"}} );
            }, 1000); // 模拟请求延迟
        });
    }

    let multiTurnChatList = [];
    let hasImage = false;
    let isStop = false;
    const  chatsSize = chats.length;
    chats.forEach(function (chat) {
       if(chatsSize > 2 && chat.image){
           isStop = true;
       }


        if(chat.type !== configFile.chat_type_other) {
            if (rolePlaying !== '' && chat.role === configFile.user_role) {
                if (chat.image) {
                    hasImage = true;
                    const data = chat.image.split(',');
                    const mimeType = data[0].split(';')[0].split(':')[1];
                    multiTurnChatList.push({role: chat.role, parts: [{text: rolePlaying + chat.statement},{inline_data: {mime_type:mimeType,data: data[1] }}  ]})
                } else {
                    multiTurnChatList.push({role: chat.role, parts: [{text: rolePlaying+chat.statement}]})

                }
            } else {
                if (chat.image) {
                    hasImage = true;
                    const data = chat.image.split(',');
                    const mimeType = data[0].split(';')[0].split(':')[1];
                    multiTurnChatList.push({role: chat.role, parts: [{text: chat.statement},{inline_data: {mime_type:mimeType,data: data[1] }}   ]})
                } else {
                    multiTurnChatList.push({role: chat.role, parts: [{text: chat.statement}]})
                }
            }
        }
    })
    if (isStop) {
        return new Promise((resolve, reject) => {
            // 模拟一个异步操作
            setTimeout(() => {
                resolve( {promptFeedback: {blockReason: "系统暂不支持带图片的多轮对话，请发起新对话并上传图片"}} );
            }, 1000); // 模拟请求延迟
        });
    }

    const generationConfig = {temperature: temperature * 0.01, topK: topK, topP: topP * 0.01}
    const requestJson = JSON.stringify({contents : multiTurnChatList, generationConfig: generationConfig, safetySettings: safetySettings });
    // console.log("question is:" + requestJson)
    // const url = apiUrl + "/v1beta/models/gemini-pro:generateContent?key=" + appkey;
    const url = apiUrl + "/v1beta/models/" +(hasImage ? "gemini-pro-vision" : "gemini-pro") + ":generateContent?key="+appkey;
    return axios({
        headers: {
            "Content-Type": "application/json"
        },
        url: url,
        method: 'POST',
        timeout: 600000,
        data: requestJson
    }).then(function (response) {
        return response.data;
    });

}

export function singleAIAnswer(appkey, apiUrl, content, temperature, topK, topP, safetySettings) {
    if (appkey ==='' || apiUrl === '') {
        return new Promise((resolve, reject) => {
            // 模拟一个异步操作
            setTimeout(() => {
                resolve( {promptFeedback: {blockReason: "请先设置App Key和Api Url"}} );
            }, 1000); // 模拟请求延迟
        });
    }
    const generationConfig = {temperature: temperature * 0.01, topK: topK, topP: topP * 0.01}

    const contents = {parts:[{text: content}]}
    const requestJson = JSON.stringify({contents : contents, generationConfig: generationConfig, safetySettings: safetySettings });
    const url = apiUrl + "/v1beta/models/gemini-pro:generateContent?key=" + appkey;
    return axios({
        headers: {
            "Content-Type": "application/json"
        },
        url: url,
        method: 'POST',
        timeout: 600000,
        data: requestJson
    }).then(function (response) {
        return response.data;
    });
}



export function formatCode(content) : string {
    const enterRExp = /\r/g;
    const enterNExp = /\n/g;
    const leftAngleExp = /</g;
    const rightAngleExp = /</g;
    const andExp = /&/g;


    content = content.replace(andExp, '&amp;').replace(leftAngleExp, '&lt;')
        .replace(rightAngleExp, '&gt;').replace(enterRExp, '<br>').replace(enterNExp, '<br>')

    content = formatStrongSymbol(content);
    content = formatCodeToBlock(content);
    // content = formatList(content);
    content=  formatChip(content);
    return content;

    // return content;
}

function formatCodeToBlock(content) : string {
    const codeRegExp = /```/;
    const replaceHeadStatement = '<div style="background-color: #282c34; color: aliceblue; padding: 10px; border-radius: 10px;"><code >';
    const replaceFootStatement = '</code></div>';
    return  formatContent(codeRegExp, content, replaceHeadStatement, replaceFootStatement);

}


// function formatList(content) : string {
//     const codeRegExp = /\*/;
//     const replaceHeadStatement = '<li>';
//     const replaceFootStatement = '</li>';
//     return  formatContent(codeRegExp, content, replaceHeadStatement, replaceFootStatement);
//
// }

function formatChip(content) : string {
    const codeRegExp = /`/;
    const replaceHeadStatement = '<span style="border-radius:5px; padding:2px; background-color: lightblue;">';
    const replaceFootStatement = '</span>';
    return  formatContent(codeRegExp, content, replaceHeadStatement, replaceFootStatement);

}
function formatStrongSymbol(content) : string {
    const codeRegExp = /\*\*/;
    const replaceHeadStatement = '<Strong>';
    const replaceFootStatement = '</Strong>';
    return  formatContent(codeRegExp, content, replaceHeadStatement, replaceFootStatement);
}


function formatContent(codeRegExp: string, content: string, replaceHeadStatement: string, replaceFootStatement: string) : string {
    while (content.match(codeRegExp) != null) {
        content = content.replace(codeRegExp, replaceHeadStatement).replace(codeRegExp, replaceFootStatement)
    }
    return content;
}
