import React from 'react';
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";

import Home from './page/Home';
import Translation from './page/Translation';
import Grammar from './page/Grammar';

class App extends React.Component {

    render() {
        return (
            <Router>
                <Routes>
                    {/*<Route  exact path="/" element={<Picture />}/>*/}
                    <Route  exact path="/" element={<Home />}/>
                    <Route  path="/Grammar" element={<Grammar />} />

                    <Route  path="/Translation" element={<Translation />} />
                </Routes>
            </Router>
        );
    }

}

export default App;
